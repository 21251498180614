import React from 'react';

// Components
import Layout from '../../components/layout';

import routes from '../../constants/routes';

const OurProcessPage = () => {
    return (
        <Layout slug={routes.our_process} title="Our Process">

        </Layout>
    );
};

export default OurProcessPage;
